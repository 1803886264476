@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap');

:root {
  --title-font: 'Bubblegum Sans', sans-serif;
  --title-font-size: 5rem;
  --title-small-font-size: 3rem;
  --title-mini-font-size: 2rem;
}

.ant-menu::after,
.ant-menu::before {
  content: none;
}

.ant-layout-sider {
  position: sticky;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: auto;
}

.ant-layout-sider-trigger {
  position: absolute;
}

.ant-space {
  margin-bottom: 0;
}

#side-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
}

.side-menu-group:first-of-type {
  margin-top: 10vh;
}

#page-content {
  max-width: 90%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('https://res.cloudinary.com/ozlizard/image/upload/v1614118902/usb_s5yhwq.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.ant-card-meta-description {
  color: black;
}

#home-header h1 {
  font-family: var(--title-font);
  font-size: var(--title-mini-font-size);
}

#home-header h3 {
  font-size: 1rem;
}

@media only screen and (min-width: 285px) {
  #home-header h1 {
    margin-top: 100px;
    font-size: var(--title-small-font-size);
  }

  #home-header h3 {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 600px) {
  #home-header h1 {
    margin-top: 100px;
    font-size: var(--title-font-size);
  }

  #home-header h3 {
    font-size: 2rem;
  }
}

.ghost-btn {
  border: 1px solid #4bbe58;
  color: #4bbe58;
  background: white;
}

.ghost-btn:hover {
  color: #3d9647;
  background: white;
}

.fade-in {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;
}

.fade-out {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
}

.ant-result-subtitle {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  color: black;
}
